<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Advances Journal
            <v-spacer></v-spacer>
            <add-button
              class="mr-3"
              :permission="'journal-create'"
              @action="payAdvanceRecord(true)"
            >
              Advance Payment
            </add-button>
            <!-- v-if="
                createJournalStats &&
                createJournalStats.total_records > createJournalStats.journaled
              " -->
            <add-button
              class="mr-3"
              :permission="'journal-create'"
              @action="fetchCreateJournalStats(true)"
            >
              Create Journal
            </add-button>
            <v-download-column-select
              v-if="
                ($auth.isAdmin() ||
                  $auth.loggedInRole().slug === 'administrator' ||
                  $auth.loggedInRole().slug === 'accountant') &&
                this.items.length > 0
              "
              :optionType="'radio'"
              :showPDF="false"
              :columnHeader="'DOWNLOAD TYPES'"
              :downloadExcelText="'Begin Download/Upload'"
              :downloadColumns="downloadColumns"
              @onDownload="downloadResult"
            >
              DOWNLOAD/UPLOAD OPTION
            </v-download-column-select>
          </v-card-title>
          <v-card>
            <v-container v-if="this.allow_posting === false">
              <alert-message
                :extraSmall="true"
                :title="`Make sure invoice is not generated.`"
                type="warning"
              >
                <template v-slot:message>
                  Since, Invoice is generated , You are not allowed to post
                  advance records.
                </template>
              </alert-message>
            </v-container>
            <v-container v-if="this.allow_posting === true">
              <alert-message
                v-if="this.allow_posting === true"
                :extraSmall="true"
                :title="`You Can Post Advance Record.`"
                type="success"
              >
                <template v-slot:message>
                  You are allowed to post advance records.
                </template>
              </alert-message>
            </v-container>
          </v-card>

          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs4 sm4>
                <v-select
                  :disabled="gradeLoading"
                  :loading="gradeLoading"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs4 sm4>
                <v-select
                  :loading="sectionLoading"
                  :disabled="!grade"
                  :items="sections"
                  class="pa-0"
                  label="Section"
                  v-model="section"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs4 sm4>
                <search-button
                  :disabled="!grade"
                  style=""
                  permission="subject-read"
                  @action="get()"
                >
                  Search
                </search-button>
              </v-flex>
            </v-card-title>
          </v-card>
          <div
            class="data-represent"
            style="margin-top: 30px"
            v-if="createJournalStats"
          >
            <div>
              <span class="d-warning"></span> &nbsp;
              <small>{{ createJournalStats.journaled }}</small>
              <strong>
                /
                {{
                  createJournalStats.total_records -
                  createJournalStats.journaled
                }}
              </strong>
              <small> (Journaled / Pending) Records</small>
            </div>
            <div>
              <span class="d-primary"></span>
              <strong>
                {{
                  createJournalStats.total_records
                    ? createJournalStats.total_records
                    : "-"
                }}</strong
              >
              <small> Total Advance Records</small>
            </div>

            <div>
              <span class="d-error"></span>
              <small> Total Advance Amount:</small> &nbsp;
              <small
                ><strong>{{
                  createJournalStats.total_amount
                    ? createJournalStats.total_amount.currency()
                    : "-"
                }}</strong></small
              >
            </div>
            <div>
              <span class="d-info"></span>
              <small> Total Opening Amount:</small> &nbsp;
              <small
                ><strong>{{
                  createJournalStats.opening_amount
                    ? createJournalStats.opening_amount.currency()
                    : "-"
                }}</strong></small
              >
            </div>
          </div>
          <br />
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            :options.sync="pagination"
            sort-by="id"
            :loading="items.loading"
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td class="text-xs-left">{{ index + 1 }}</td>
                <td class="text-xs-left">{{ item.roll ? item.roll : "-" }}</td>
                <td class="text-xs-left">
                  {{ item.enroll_code ? item.enroll_code : "-" }}
                </td>
                <td class="text-xs-left">{{ item.name ? item.name : "-" }}</td>
                <td class="text-xs-left">
                  {{ item.grade.replace(/"/g, " ") }} /
                  {{ item.section.replace(/"/g, " ") }}
                </td>
                <td class="text-right">
                  <span>
                    <input
                      @wheel="$event.target.blur()"
                      @keyup="updateReady = true"
                      @focus="$event.target.select()"
                      @keyup.enter="saveAdvance(item)"
                      type="number"
                      class="inputType"
                      v-model="item.opening_amount"
                      :disabled="
                        parseInt(item.stat) === 1 || allow_posting === false
                      "
                    />
                  </span>
                </td>
                <!-- @wheel="$event.target.blur()"
                      @keyup="updateReady = true"
                      @focus="$event.target.select()"
                      @keyup.enter="saveAdvance(item)" -->
                <td class="text-right">
                  <span>
                    <input
                      type="number"
                      class="inputType"
                      v-model="item.amount"
                      :disabled="true"
                    />
                  </span>
                </td>
              </tr>
              <tr
                class="error"
                style="color: white; font-weight: bold"
                v-if="index === items.length - 1"
              >
                <td></td>
                <td></td>
                <td>Total</td>
                <td></td>
                <td></td>
                <td>
                  {{
                    totalAdvanceAmount
                      ? totalAdvanceAmount.currency()
                      : "RS.0.00"
                  }}
                </td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
          <v-btn
            :loading="saving"
            @click="saveAdvance"
            v-if="
              items.length > 0 &&
              enableSaveButton &&
              this.allow_posting === true
            "
            block
            :disabled="!updateReady || saving"
            color="primary"
            large
            >Save Advance Record</v-btn
          >
          <input
            @change="uploadExcelFile"
            style="display: none"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            type="file"
            ref="upload_advance_input"
          />
        </v-card>
      </v-flex>

      <v-dialog v-model="payAdvanceModal" persistent max-width="800">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Advance Payment (Bulk)</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <alert-message
              extraSmall
              type="warning"
              title="Pay Advance Amount"
              message="All Your Advance Amount Will Be Paid. No further edit is allowed after the process is completed."
            ></alert-message>
            <v-flex xs12>
              <table
                class="basic_styled_table"
                width="100%"
                border="1"
                cellpadding="5"
                cellspacing="5"
              >
                <thead>
                  <tr>
                    <th>Total Advance Amount</th>
                    <th style="background-color: #f20717">Total Dues Amount</th>
                    <th style="background-color: #0000ff">Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>{{
                        advanceDueRecord && advanceDueRecord.advance_amount
                          ? advanceDueRecord.advance_amount.currency()
                          : "-"
                      }}</strong>
                    </td>
                    <td>
                      <strong>{{
                        advanceDueRecord && advanceDueRecord.due_amount
                          ? advanceDueRecord.due_amount.currency()
                          : "-"
                      }}</strong>
                    </td>
                    <td>
                      <view-button
                        @action="dueAdvanceDetails()"
                        permission="student-update"
                        icon="autorenew"
                        color="warning"
                        label="Details"
                      >
                      </view-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <v-textarea
                v-model="advance_remarks"
                outlined
                label="Narration"
                rows="3"
              ></v-textarea>
              <br />
              <br />
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="payAdvanceModal = false"
              >Close</v-btn
            >
            <v-btn
              :disabled="advance_remarks.length < 4"
              color="warning"
              text
              @click="payBulkAdvance()"
              >Confirm & Pay</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dueAdvanceList" persistent max-width="800">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Student List</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-flex xs12>
              <table
                class="basic_styled_table"
                width="100%"
                border="1"
                cellpadding="5"
                cellspacing="5"
              >
                <thead>
                  <tr>
                    <th>Student Name</th>
                    <th>Due Amount</th>
                    <th>Advance Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in studentDueAdvanceRecord"
                    v-bind:key="item.name"
                  >
                    <td>
                      <strong>{{ item.name ? item.name : "-" }}</strong>
                    </td>
                    <td>
                      <strong>{{
                        item.due_amount ? item.due_amount.currency() : "-"
                      }}</strong>
                    </td>
                    <td>
                      <strong>{{
                        item.advance_amount
                          ? item.advance_amount.currency()
                          : "-"
                      }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="dueAdvanceList = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="createJournalModal" persistent max-width="800">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Create Journal</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <alert-message
              extraSmall
              type="warning"
              title="Create Journal Alert"
              message="By creating journal all your advance records will be synced to accounting system. No further edit/create is allowed after the sync process is complete"
            ></alert-message>

            <v-flex xs12>
              <!-- v-if="createJournalStats" -->
              <table
                class="basic_styled_table"
                width="100%"
                border="1"
                cellpadding="5"
                cellspacing="5"
              >
                <thead>
                  <tr>
                    <th>Total Advance Amount</th>
                    <th>Journal Complete / Pending</th>
                    <th>Total Records</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>{{
                        createJournalStats.opening_amount
                          ? createJournalStats.opening_amount.currency()
                          : "-"
                      }}</strong>
                    </td>
                    <td>
                      <strong
                        >{{ createJournalStats.journaled }}
                        /
                        {{
                          createJournalStats.total_records -
                          createJournalStats.journaled
                        }}</strong
                      >
                    </td>

                    <td>
                      <strong>{{
                        createJournalStats.total_records
                          ? createJournalStats.total_records
                          : "-"
                      }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <v-textarea
                v-model="journal_remarks"
                outlined
                label="Narration"
                rows="3"
              ></v-textarea>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="createJournalModal = false"
              >Close and Verify Again</v-btn
            >
            <v-btn
              :disabled="journal_remarks.length < 6"
              color="warning"
              text
              @click="createToJournal(createJournalStats.opening_amount)"
              >I have verified all the records</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Mixins from "@/library/Mixins";
import { redirectDownloadUrl } from "../../../../library/helpers";
export default {
  mixins: [Mixins],
  data: () => ({
    gradeLoading: false,
    sectionLoading: false,
    advanceDueRecord: null,
    createJournalStats: null,
    studentDueAdvanceRecord: null,
    dueAdvanceList: false,
    payAdvanceModal: false,
    createJournalModal: false,
    allow_posting: "",
    journal_remarks: "",
    advance_remarks: "",
    grades: [],
    grade: "",
    sections: [],
    section: "",
    items: [],
    updateReady: false,
    saving: false,
    pagination: {
      rowsPerPage: 1000,
      itemsPerPage: 200,
    },
    downloadUrl: "",
    totalAdvanceAmount: 0,
    accountServiceStatus: false,
    headers: [
      { text: "S.No.", align: "left", value: "seriel_no", sortable: false },
      { text: "Roll", align: "left", value: "id", sortable: false },
      {
        text: "Enroll Code",
        align: "left",
        value: "enroll_code",
        sortable: false,
      },
      { text: "Name", align: "left", value: "name", sortable: false },
      {
        text: "Grade / Section",
        align: "left",
        value: "grade",
        sortable: false,
      },
      {
        text: "Opening Amount",
        align: "right",
        value: "opening_amount",
        sortable: false,
      },
      { text: "Amount", align: "right", value: "amount", sortable: false },
    ],
    downloadColumns: [
      {
        value: "download_all_report",
        name: "Download All Advance Report",
        selected: false,
      },
      {
        value: "download_report",
        name: "Download Advance Report",
        selected: false,
      },
      {
        value: "download_all_opening_report",
        name: "Download All Opening Advance Report",
        selected: false,
      },
      {
        value: "download_opening_report",
        name: "Download Opening Advance Report",
        selected: false,
      },
      {
        value: "download_excel_raw",
        name: "Download Excel File (RAW)",
        selected: false,
      },
      { value: "upload_excel", name: "Upload Excel", selected: false },
    ],
  }),
  computed: {
    ...mapState(["batch"]),
    enableSaveButton() {
      let totalJournaledCount = 0;
      this.items.map((item) => {
        if (item.stat === "1") {
          totalJournaledCount++;
        }
      });
      return totalJournaledCount != this.items.length;
    },
  },
  mounted() {
    this.getGrades();
    this.fetchCreateJournalStats();
    if (this.$auth.getServices("ACCOUNT")) this.accountServiceStatus = true;
    else this.accountServiceStatus = false;
    // this.payAdvanceRecord();
  },
  watch: {
    pagination: function () {},
    grade: function () {
      this.section = "";
      this.sections = [];
      this.getSection();
    },
    section: function () {
      this.month = "";
    },
  },
  methods: {
    downloadResult({ type, columns, columnString }) {
      switch (columnString) {
        case "download_all_report":
          //downloadUrl=https://reporting.sahakaryatech.com/download/billing/advance-journal/1:40?gradeId=64&sectionId=184&type= 
          // window.open(this.downloadUrl + "excel" + "&all=true"+ "&token=877", "_blank");

          redirectDownloadUrl({
            uri: "/download/billing/advance-journal",
            queryString: `gradeId=${this.grade}&sectionId=${this.section}&type=excel&all=true`,
          });
          break;
        case "download_report":
          //downloadUrl = https://reporting.sahakaryatech.com/download/billing/advance-journal/1:40?gradeId=64&sectionId=184&type=
          // window.open(this.downloadUrl + "excel", "_blank");
          redirectDownloadUrl({
            uri: "/download/billing/advance-journal",
            queryString: `gradeId=${this.grade}&sectionId=${this.section}&type=excel`,
          });
          break;
        case "download_all_opening_report":
          //https://reporting.sahakaryatech.com/download/billing/advance-journal/1:40?gradeId=64&sectionId=184&type=excel&all=true&opening=true
          // window.open(
          //   this.downloadUrl + "excel" + "&all=true" + "&opening=true",
          //   "_blank"
          // );
          redirectDownloadUrl({
            uri: "/download/billing/advance-journal",
            queryString: `gradeId=${this.grade}&sectionId=${this.section}&type=excel&all=true&opening=true`,
          });
          break;
        case "download_opening_report":
          //https://reporting.sahakaryatech.com/download/billing/advance-journal/1:40?gradeId=64&sectionId=184&type=excel&opening=true
          // window.open(this.downloadUrl + "excel" + "&opening=true", "_blank");

          redirectDownloadUrl({
            uri: "/download/billing/advance-journal",
            queryString: `gradeId=${this.grade}&sectionId=${this.section}&type=excel&opening=true`,
          });
          break;
        case "download_excel_raw":
          redirectDownloadUrl({
            uri: "/download/student/list",
            queryString: `gradeId=${this.grade}&flag=all&type=excel&column=enroll_code,enroll_id,name,grade,section,amount&list_type=CURRENT`,
          });
          break;
        case "upload_excel":
          this.$refs.upload_advance_input.click();
          break;
      }
    },
    uploadExcelFile(e) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("advance_sheet", file);
      this.$rest
        .post(`/api/advance/upload`, formData)
        .then(() => {
          this.$events.fire("notification", {
            message: "Uploaded successfully",
            status: "success",
          });
          this.get();
          this.fetchCreateJournalStats();
          this.updateReady = false;
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        })
        .finally(() => {
          this.$refs.image.value = null;
        });
    },
    fetchCreateJournalStats(openModal = false) {
      // this.createJournalModal = openModal;
      this.$rest.get("/api/advance-journal/stats").then(({ data }) => {
        this.createJournalStats = data;
        this.createJournalModal = openModal;
        // if (data.total_amount > 0) {
        //   console.log('sagar');
        // }
      });
    },
    payAdvanceRecord(openModal = false) {
      // this.payAdvanceModal = true;

      this.$rest.get("/api/advance-due-record").then(({ data }) => {
        this.advanceDueRecord = data;
        if (data.advance_amount > 0) {
          this.payAdvanceModal = openModal;
        }
      });
    },
    dueAdvanceDetails() {
      this.$rest.get("/api/student-advance-due-list").then(({ data }) => {
        this.studentDueAdvanceRecord = data;
        if (data) {
          this.dueAdvanceList = true;
        }
      });
    },
    get() {
      let extraParams = `grade=${this.grade}&section=${this.section}`;
      this.$rest
        .get("api/advance-journal" + this.queryString(extraParams))
        .then(({ data }) => {
          let sumAmount = 0;
          data.data.map((res) => {
            sumAmount += res.opening_amount;
          });
          this.totalAdvanceAmount = sumAmount;
          this.items = data.data;
          this.downloadUrl = data.download_url;
          this.allow_posting = data.allow_posting;
        });
    },
    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get("/api/grades?rowsPerPage=25&descending=false&slim=true")
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    getSection() {
      if (this.grade) {
        this.sectionLoading = true;
        this.$rest
          .get("/api/section?grade=" + this.grade)
          .then(({ data }) => {
            this.sections = data.data.map((item) => {
              return { value: item.id, text: item.name };
            });
          })
          .finally(() => {
            this.sectionLoading = false;
          });
      }
    },
    payBulkAdvance() {
      this.$rest
        .post("/api/advance/bulk-pay")
        .then(() => {
          this.$events.fire("notification", {
            message: "Advance Amount Paid successfully",
            status: "success",
          });
          this.payAdvanceModal = false;
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        })
        .finally(() => {});
    },
    createToJournal(openingAmount) {
      this.$rest
        .post("/api/journal-entry/advance-posting", {
          amount: openingAmount,
          account_service_status: this.accountServiceStatus,
          narration: this.journal_remarks,
        })
        .then(({ data }) => {
          this.$events.fire("notification", {
            message: data.message,
            status: "success",
          });
          this.get();
          this.fetchCreateJournalStats();

          this.createJournalModal = false;
          this.journal_remarks = "";
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        })
        .finally(() => {});
    },
    saveAdvance(item) {
      this.saving = true;
      this.$rest
        .post("/api/advance-journal", {
          advance_amount: item && item.opening_amount ? [item] : this.items,
        })
        .then((res) => {
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
          this.fetchCreateJournalStats();
          this.updateReady = false;
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        })
        .finally(() => {
          this.saving = false;
          this.get();
        });
    },
  },
};
</script>
<style lang="scss">
.verified {
  background: #4caf50 !important;
  color: white !important;
  padding: 5px 10px 5px 5px !important;
  border-radius: 5px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.total {
  background: red !important;
  color: white !important;
  padding: 5px 10px 5px 5px !important;
  border-radius: 5px !important;
}

.unverified {
  background: #fb8c00 !important;
  color: white !important;
  padding: 5px 10px 5px 5px !important;
  border-radius: 5px !important;
}

.inputType {
  border: 1px solid #bbb;
  text-align: center;
  height: 34px;
  width: 86px;
  &:focus {
    outline: none;
  }
  &[disabled] {
    color: #999;
  }
}

.inputType:disabled {
  cursor: not-allowed;
  background: #eee;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
